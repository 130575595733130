.container {
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;

    padding-left: 20rem;
    padding-right: 20rem;
    padding-top: 5rem;
    width: 100%;
    
    .row {
        display: flex;
        flex: 0 0 100%;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 3rem;

        .progress {
            flex: 2;
        }

        .schema-id {
            flex-grow: 1;
            margin-right: 1em;
        }
    }

}

.error {
    color: red;
    padding-left: 1rem;
}