.resizableImageViewer {
    min-width: 100px;
    min-height: 100px;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;

    .image {
        background: none transparent;
        border: none;
        margin: 0px;
        padding: 0px;
        height: calc(100% - 40px);
    }
}

.toolbar {
    position: relative;

    .actions-container {
        float: right;
        margin: 10px 20px 0 0;

        a {
            &::before {
                content: "|";
                margin-left: 0.25em;
                margin-right: 0.25em;
            }

            &:first-of-type::before {
                content: "";
            }
        }
    }

    .toolbar-action {
        display: inline-block;
        position: relative;
    }
}

.Resizer {
    background: gray;
    height: 1em;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.Resizer.horizontal {
    height: 1em;
    margin: -5px 0;
    border: 1px solid;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
    cursor: not-allowed;
}
.Resizer.disabled:hover {
    border-color: transparent;
}

.SplitPane {
    position: relative;
}

.Pane2 {
    position: relative;
    display: flex;
    flex-grow: 1;
    margin: 1em;
}