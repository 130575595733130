.schema-container {
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;

    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
    min-width: 800px;
    
    .row {
        display: flex;
        flex: 0 0 100%;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;

        .flex-item-fill {
            flex-grow: 1;
            padding-right: 1rem;
        }

    }

    .row-divider {
        margin: 1rem 0;
    }

}